<script setup lang="ts">
import { RouterView } from 'vue-router'
import { loadClarityScript } from '@/lib/clarity'
import { storeToRefs } from 'pinia'
import { useTheme } from 'vuetify'
import { useAuthStore } from './stores/auth'
import { watch } from 'vue'

const theme = useTheme()
const authStore = useAuthStore()
const { organization } = storeToRefs(authStore)

const organizationThemes: Record<string, string> = {
  Directo: 'directo',
  // Qualia: 'qualia',
}

theme.global.name.value = organization.value?.name
  ? organizationThemes[organization.value.name] || 'default'
  : 'default'

const clarityId = import.meta.env.VITE_APP_CLARITY_ID
if (clarityId) {
  loadClarityScript(clarityId)
}

watch(organization, (newOrg) => {
  theme.global.name.value = newOrg?.name ? organizationThemes[newOrg.name] || 'default' : 'default'
})
</script>

<template>
  <v-app>
    <RouterView />
  </v-app>
</template>

<style scoped></style>
