import { axiosInstance as axios } from '@/services'

export const AxiosInterceptor = () => {
  const updateHeader = (request: any) => {
    const token = localStorage.getItem('token')

    const authUrl = ['/login/', '/directo-autologin/']

    if (token && !authUrl.includes(request.url)) {
      const newHeaders = {
        Authorization: `JWT ${token}`,
      }

      request.headers = newHeaders
    }

    return request
  }

  axios.interceptors.request.use((request) => {
    return updateHeader(request)
  })

  axios.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      const statusCode = error.response ? Number(error.response.status) : null
      const ignoreUrls = ['/directo-autologin/']
      const requestUrl = error.config?.url

      if (statusCode === 401 && requestUrl && !ignoreUrls.includes(requestUrl)) {
        localStorage.clear()
        location.reload()
        return
      }
      return Promise.reject(error)
    }
  )
}
