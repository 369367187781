import { acceptHMRUpdate, defineStore, storeToRefs } from 'pinia'
import { computed } from 'vue'
import { useAuthStore } from './auth'
import type { TOrganization } from '@/models'

const permissions = {
  canSeeAllRequests: 'RapiHogarWeb.view_all_requests_of_my_organization',
  canSeeMyRequests: 'RapiHogarWeb.view_only_my_requests',
  canCreateRequests: 'RapiHogarWeb.add_pedido',
  canCreateAssistanceRequests: 'RapiHogarWeb.can_create_corpo_assistance_request',
  canSeeChatRequests: 'RapiHogarWeb.view_chat',
  canSeeInboxNotification: 'RapiHogarWeb.view_notification',
  canChangeRequestResponsible: 'RapiHogarWeb.assign_a_user_to_the_request',
  canHandleBudgets: 'approve_budgets',
  canSeeOnlyAssists: 'RapiHogarWeb.view_only_assists',
  canSeeOnlyClaims: 'RapiHogarWeb.view_only_siniestros',
  canSeeOnlyGruas: 'RapiHogarWeb.ver_solo_esquema_gruas',
  canSeeOnlyAsistenciaVehicular: 'RapiHogarWeb.ver_solo_esquema_asistencia_vehicular',
  canSeeOnlyReintegro: 'RapiHogarWeb.ver_solo_esquema_reintegro',
  canSeeOnlyTrash: 'RapiHogarWeb.ver_solo_esquema_trash',
  canSeeOnlyCristales: 'RapiHogarWeb.ver_solo_esquema_cristales',
  canSeeOnlyTelemedicina: 'RapiHogarWeb.ver_solo_esquema_telemedicina',
  canSeeOnlyLiquidacion: 'RapiHogarWeb.ver_solo_esquema_liquidacion',
  canAddClient: 'RapiHogarWeb.add_cliente',
  canSeeStatistics: 'RapiHogarWeb.can_see_statistics',
  canSeeAssistanceChat: 'RapiHogarWeb.can_see_the_chat_for_asistencia_scheme',
  canSeeClaimChat: 'RapiHogarWeb.can_see_the_chat_for_siniestros_scheme',
  canSeeRefundChat: 'RapiHogarWeb.can_see_the_chat_for_reintegro_scheme',
  canCreateSubscription: 'RapiHogarWeb.add_subscription',
  canSeeSubscription: 'RapiHogarWeb.view_subscription',
  canCreateSiniestrosRequests: 'RapiHogarWeb.can_create_corpo_siniestro_request',
  canManageTeam: 'RapiHogarWeb.can_edit_team_on_corpo',
} as const

type PermissionsType = keyof typeof permissions

export const usePermissions = defineStore('permissions', () => {
  const auth = useAuthStore()
  const { user, organization } = storeToRefs(auth)

  const currentUserPermissions = computed(() => {
    return user.value.permissions ?? []
  })

  const userPermissions = computed(() => {
    const tempPermissions: Record<PermissionsType, boolean> = {} as Record<PermissionsType, boolean>

    Object.entries(permissions).forEach(([key, permission]) => {
      const orgCondition = getOrganizationCondition(key, organization.value)

      tempPermissions[key as PermissionsType] =
        searchPermission(currentUserPermissions.value, permission) && orgCondition
    })

    return tempPermissions
  })

  const userHasMultipleSchemes = computed(() => {
    const schemePermissions = [
      permissions.canSeeOnlyAssists,
      permissions.canSeeOnlyClaims,
      permissions.canSeeOnlyGruas,
      permissions.canSeeOnlyAsistenciaVehicular,
      permissions.canSeeOnlyReintegro,
      permissions.canSeeOnlyTrash,
      permissions.canSeeOnlyCristales,
      permissions.canSeeOnlyTelemedicina,
      permissions.canSeeOnlyLiquidacion,
    ]

    const filtered = schemePermissions.filter(Boolean)
    return filtered.length > 1
  })

  return {
    userPermissions,
    userHasMultipleSchemes,
  }
})

function getOrganizationCondition(permissionKey: string, organization: TOrganization | null): boolean {
  const conditions: Record<string, boolean> = {
    canCreateRequests: !!organization?.canCreateRequest,
    canSeeStatistics: !!organization?.showCorpoStats,
  }
  return conditions[permissionKey] ?? true
}

function searchPermission(permissionList: string[], permission: string): boolean {
  return permissionList.map((p) => p.toLowerCase()).includes(permission.toLowerCase())
}

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(usePermissions, import.meta.hot))
}
