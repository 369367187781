import { defineStore } from 'pinia'
import { computed, ref, watchEffect } from 'vue'
import { useStorage } from '@vueuse/core'
import router from '@/router'

import type { User } from '../types'
import { userAutoLogin, userLogin, type LoginParams, type AutoLoginParams } from '@/services/auth.services'
import { createOrganizationAdapter } from '../adapters'

export const useAuthStore = defineStore('auth', () => {
  const isAuthenticated = ref(false)
  const user = useStorage('user', {} as User)
  const token = useStorage('token', '')
  const INSURANCE = 'insurance'

  const organization = computed(() => {
    return createOrganizationAdapter(user.value.organization)
  })

  const isAnInsurance = computed(() => {
    return organization.value?.extraData.some((item) => item === INSURANCE)
  })

  watchEffect(() => {
    if (token.value) {
      isAuthenticated.value = true
    }
  })

  async function login(params: LoginParams) {
    const { data } = await userLogin(params)
    user.value = data
    token.value = data.token
    router.push('/')
  }

  async function autologin(params: AutoLoginParams) {
    try {
      const { data } = await userAutoLogin(params)
      user.value = data
      token.value = data.token
      return data
    } catch (error: any) {
      if (error.response?.status === 400) {
        throw new Error('Clave inválida. Revisa el enlace o solicita una nueva.')
      }
      if (error.response?.status === 401) {
        throw new Error('Clave expirada. Solicita una nueva clave de acceso.')
      }
      if (error.response?.status === 404) {
        throw new Error('Clave no encontrada. Solicita una nueva clave de acceso.')
      }
      throw new Error('Autologin falló')
    }

    // TODO: Hacer petición a ws de validación de usuario
    // const { data } = await userAutoLogin(params)
    // return new Promise((resolve, reject) => {
    //   if (Math.random() < 0.5) {
    // TODO: Si la petición es exitosa, se debe guardar el usuario en el localStorage y redirigir a la página de inicio o a donde pidan
    //     console.log('data autologin', data)
    //     console.log('params', params)
    //     user.value = data
    //     token.value = data.token
    //     setTimeout(() => {
    //       resolve(`autologin success ${params.key}`)
    //       console.log('exito', params.key)
    //     }, 1000)
    //   } else {
    //     reject('autologin error')
    //   }
    // })
  }

  return { isAuthenticated, user, token, organization, isAnInsurance, login, autologin }
})
